import logo from './logo.svg';
import './App.css';
import logoo from "./assets/logo.png";
import mario from "./assets/mario.png";
import solana from "./assets/solana.png";
import mariom from "./assets/mariom.png";
import test from "./assets/test.gif";
import {Step} from "./dates";
import foot from "./assets/mariofoot.png";
import { FaTwitter } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";

function App() {
  return (
    <div className="App">
      <div className='head'>
      <img src={mario} className='headlogo'/>
      <div className='header'>
        <a href='https://raydium.io/' target="_blank">
     <h1 className='headername'>buy</h1></a>
     <a href='https://marioonsol.netlify.app/' target="_blank">
     <h1 className='headername'>play</h1></a>
     <a href='https://dexscreener.com/' target="_blank">
     <h1 className='headername'>chart</h1></a>
      </div>
      <img src={solana} className='headlogo'/>
    </div>
    <img src={logoo} className='mainlogo'/>
    <img src={foot} className='footerrr'/>
    <div className='about'>
      <div className='box1'>
        <p className='aboutme'>Welcome to the thrilling world of $mario, where the excitement of Mario meets the adventure of Dinosaurs! Join us on Solana's blockchain for an electrifying gaming experience that blends the classic charm of Mario with the prehistoric allure of Dynos. Embark on a journey through vibrant worlds, overcoming challenges, collecting treasures, and conquering levels—all while earning tokens with every triumphant step. Get ready to jump, dash, and explore your way to rewards in this unique fusion of timeless gaming nostalgia and cutting-edge blockchain technology!</p>
      </div>
      <div className='box2'>
      <img src={mariom} className='mariom'/>
      </div>
    </div>
    <img src={test} className='gif'/>
    <br></br>
    <a href='https://marioonsol.netlify.app/'><button className='trybutton'>try the game</button></a>
    <h1 className='roadmap'>Roadmap</h1>
    <div className='stepp'>
    <Step />
    </div>
    <h1 className='roaadmap'>tokenomics</h1>
    <div className="flex h-screen">
      <div className="m-auto">
        <div className="shadow-md p-4 flex flex-row bg-yell rounded-lg">
          <div className="bg-red-500 inline-block bg-yell rounded-lg p-1 mr-1"></div>
          <b className="p-1">Total Supply</b>
          <p className="p-1">10,000,000,000 $MARIO </p>
        </div>
        <br></br>
        <div className="shadow-md p-4 flex bg-yell flex-row rounded-lg">
          <div className="bg-yellow-500 inline-block rounded-lg p-1 mr-1"></div>
          <b className="p-1">Liquidity</b>
          <p className="p-1">8,000,000,000 $MARIO</p>
         
        </div>
        <br></br>
        <div className="shadow-md p-4 flex bg-yell flex-row rounded-lg">
          <div className="bg-green-500 inline-block rounded-lg p-1 mr-1"></div>
          <b className="p-1">Marketing and Devlopment</b>
          <p className="p-1">2,000,000,000 $MARIO</p>
         
        </div>
      </div>
    </div>
   <div className='footer'>
   <div className='icons'>
    <a href='https://twitter.com/marioonsolana' target="_blank" >
    <FaTwitter className='icon'/></a>
    <a href='https://t.me/marioonsolanaa' target="_blank" >
    <FaTelegramPlane className='icon' /></a>
    </div>
   <p className='copy' copyright reserved to mario on sol > copyright reserved to mario on sol </p>
   </div>
    </div>
  );
}

export default App;
